<template>
  <main v-if="!mobile" class="dgrid-body flow" style="--flow-space: var(--s4)"  :class="{mobile: mobile}">
    <div class="flow" style="--flow-space: var(--s1)">
      <h2 class="view-title">{{ $t('search') }}</h2>
      <p class="text--subtitle view-subtitle">{{ subtitle }}</p>      
    </div>

    <interface-main-input
      :custom-cb="submit"
    />

    <div id="filters" class="stack-horizontal" data-wrap>
      <button v-for="filter in filters" @click="setFilter(filter.query)" :class="{'text--body1-medium': filter.query == selectedFilter }">{{ filter.label }} ({{ getCountByQuery(filter.query) }})</button>
    </div>

    <div id="no-results-block" v-if="noResultsCurrent" class="bordered-top">
      <h3>{{ $t('no_results') }}</h3>
    </div>

    <div v-else id="results-block" class="flow">
      <div class="dgrid-container dgrid-flex dgrid-flex-minmax">


        <template v-if="loading">
          <base-cs-sheet
            v-for="i in 12"
            :key="i"
            skeleton
            ignore-max-width
          />
        </template>

        <template v-else>
          <template v-for="item in results">

            <base-cs-sheet
              :key="item.id"
              v-if="item.class == 'EthnographicSource'"
              :item="item"
              @clicked="$router.push({name: 'sheet', params: {id: item.id.toString(), slug: item.slug}})"
            />

            <base-cs-event 
              :key="item.id"
              v-else-if="item.class == 'Event'"
              :item="item"
              @clicked="$router.push({name: 'event', params: { slug: item.slug }})"
            />

            <base-cs-story 
              :key="item.id"
              v-else-if="item.class == 'Story'"
              :item="item"
              @clicked="$router.push({name: 'story', params: { slug: item.slug }})"
            />

            <base-cs-institution
              :key="item.id"
              :item="item"
              v-else-if="item.class == 'Institution'"
              @clicked="$router.push({name: 'institution', params: { slug: item.slug }})"
            />
          </template>
        </template>

      </div>

      <div v-if="results.length < countByType(selectedFilterObj.resultsKey)" class="cover">
        <base-button :text="$t('global_bottone_espandi')" @clicked="showMore"/>
      </div>

    </div>

  </main>
  
  <main v-else  :class="{mobile: mobile}">
    
    <section id="search-bar" class="full-width" :class="{expanded: !searchString}"> 
      <div>
        <div class="dgrid-body stack-horizontal full-height" data-align="center">
          <base-icon name="search"/>
          <form @submit="submit(inputSearchString);"><input type="text" class="full-width" v-model="inputSearchString"></form>        
        </div>        
      </div>
    </section>

    <section id="sorting">
      
    </section>

    <section id="counter" class="text--body2 bordered-bottom">
      <p class="dgrid-body">{{ count }} {{ $t('results') }}</p>
    </section>

    <section id="filters" class="stack-horizontal text--body1">
      <button v-for="filter in filters" @click="setFilter(filter.query)" :class="{'text--body1-medium': filter.query == selectedFilter }">{{ filter.label }} ({{ getCountByQuery(filter.query) }})</button>
    </section>

    <div id="no-results-block" v-if="noResultsCurrent" class="bordered-top dgrid-body">
      <h3>{{ $t('no_results') }}</h3>
    </div>    

    <div v-else id="results-block" class="dgrid-body flow">
      <div class="dgrid-container">
        <template v-if="loading">
          <base-cs-sheet
            v-for="i in 12"
            :key="i"
            type="list"
            skeleton
            ignore-max-width
          />
        </template>

        <template v-else>
          <template v-for="item in results">

            <base-cs-sheet
              :key="item.id"
              type="list"
              v-if="item.class == 'EthnographicSource'"
              :item="item"
              @clicked="$router.push({name: 'sheet', params: {id: item.id.toString()}})"
            />

            <base-cs-event 
              :key="item.id"
              type="list"
              img-aspect-ratio="1/1"
              :like="false"
              v-else-if="item.class == 'Event'"
              :item="item"
              @clicked="$router.push({name: 'event', params: { slug: item.slug }})"
            />

            <base-cs-story
              type="list"
              img-aspect-ratio="1/1"
              :like="false"
              :key="item.id"
              v-else-if="item.class == 'Story'"
              :item="item"
              @clicked="$router.push({name: 'story', params: { slug: item.slug }})"
            />

            <base-cs-institution
              type="list"
              img-aspect-ratio="1/1"
              :key="item.id"
              :like="false"
              :item="item"
              v-else-if="item.class == 'Institution'"
              @clicked="$router.push({name: 'institution', params: { slug: item.slug }})"
            />
          </template>
        </template>
      </div>

      <div v-if="results.length < countByType(selectedFilterObj.resultsKey)" class="cover">
        <base-button :text="$t('global_bottone_espandi')" @clicked="showMore"/>
      </div>

    </div>

  </main>

</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import InterfaceMainInput from '@components/InterfaceMainInput.vue';
import langMixin from '@mixins/lang.js';

export default {
  name: 'SearchAll',
  mixins: [ langMixin ],
  components: { InterfaceMainInput },
  data() {
    return {
      filters: [
        {
          label: this.$t('global_filtro_elementi_tutti'),
          query: 'all',
          resultsKey: 'all'
        },
        {
          label: this.$t('global_filtro_elementi_schede'),
          query: 'source',
          resultsKey: 'nEthnographicSources'
        },
        {
          label: this.$t('global_filtro_elementi_storie'),
          query: 'story',
          resultsKey: 'nStories'
        },
        {
          label: this.$t('global_filtro_elementi_eventi'),
          query: 'event',
          resultsKey: 'nEvents'
        },
        {
          label: this.$t('global_filtro_elementi_enti'),
          query: 'institution',
          resultsKey: 'nInstitutions'
        },
        // {
        //   label: this.$t('global_filtro_elementi_press_kit'),
        //   query: 'article',
        //   resultsKey: 'nArticles'
        // }
      ],
      selectedFilter: 'all',
      resultClassesMap: {
        Story: {
          cardType: 'event'
        },
        Event: {
          cardType: 'event'
        },
        EthnographicSource: {
          cardType: 'carousel'
        },
        Institution: {
          cardType: 'card'
        }
      },
      inputSearchString: undefined
    }
  },
  computed: {
    ...mapState("search", ["results", "page", "loading", "pageSize"]),
    ...mapGetters("search", ["count", "countByType"]),
    ...mapState("interface", ["mobile"]),
    searchString() {
      return this.$route.query.search;
    },
    selectedFilterObj() {
      return this.filters.find(el => el.query == this.selectedFilter);
    },
    noResults() {
      return !this.loading && this.searchString && !this.count;
    },
    noResultsCurrent() {
      return !this.loading && this.searchString && !this.countByType(this.selectedFilterObj.resultsKey);
    },
    subtitle() {
      let rtn = "";
      if (!this.loading && this.searchString) {
        rtn = `${this.count} ${this.$t('results_for')} ${this.searchString}`
      }
      return rtn;
    }
  },
  watch: {
    searchString(n, o) {
      if (n != o && this.searchString) {
        this.setPage(1);
        this.getData();
      }
    }
  },
  methods: {
    ...mapActions("search", ["search"]),
    ...mapMutations("search", ["setPage"]),
    langCb() {
      this.getData();
    },
    getData() {
      let args = {
        page_size: this.pageSize,
        search: this.searchString,
        type: this.selectedFilter
      }
      this.search(args);
    },
    showMore() {
      this.setPage(this.page + 1);
      this.getData();
    },
    submit(string) {
      this.$router.push({query: {search: string, type: this.selectedFilter}})
      this.setPage(1);
      if (string) this.getData();
    },
    setFilter(query) {
      this.selectedFilter = query;
      this.submit(this.searchString);
    },
    getCountByQuery(query) {
      let filter = this.filters.find(el => el.query == query);
      return this.countByType(filter.resultsKey);
    },
    handleKeyUp(e) {
      e.preventDefault();
      this.submit(this.inputSearchString);
    }
  },
  mounted() {
    this.setPage(1);
    if (this.searchString) {
      this.inputSearchString = this.searchString;
      this.getData();
    }
    else this.noActiveSearch = true;
  }
}
</script>

<style lang="scss">

main#search.view {
  margin-bottom: var(--s5);
}

#search.view {

  #filters {
    --flow-space-horizontal: var(--s3);
    overflow: auto;
    button {
      flex-shrink: 0;
      &.text--body1-medium {
        text-decoration: underline;
      }
    }
  }

  #no-results-block {
    padding: 5.5rem 0;
  }

  .cs-sheet,
  .cs-event,
  .cs-story,
  .cs-institution {
    --dgrid-maxw: var(--dgrid-item-w);   
  }

  .cs-sheet {
    --dgrid-minw: 200px;
    --dgrid-item-col: 3;
  }

  .cs-event,
  .cs-story,
  .cs-institution {
    --dgrid-minw: 300px;
    --dgrid-item-col: 4;
  }

  #search-bar { /*mobile searchbar*/
    background-color: var(--color-primary-10);

    &.expanded {
      height: 100vh;
    }

    > div:first-child {
      height: var(--navbar-height);
    }
  }

  #counter {
    padding: var(--s0) 0;
  }
}

#search.view.mobile {
  #filters {
    padding: var(--s1) var(--dgrid-body-margin);
  }
  #no-results-block {
    padding: 1.5rem 0;
  }
}
</style>
